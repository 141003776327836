import DirectusSDK from '@directus/sdk-js';

const client = new DirectusSDK({
    url: window.location.hostname === 'test.iog-austria.at' ? 'https://test-admin.iog-austria.at' : 'https://admin.iog-austria.at',
    project: 'iog',
    storage: window.localStorage,
    token: 'WY#{%3uv;6v)[<Z/ARu!9t=Gk%NaKm4S',
});

export default client;
